@require "var.styl"

texthidden(){
	overflow hidden
	text-indent -9999px
}

clearfix(){
	&:after{
		content ''
		display block
		clear both
	}
}

textellipsis(){
	overflow hidden
	text-overflow ellipsis
	white-space nowrap
}

textellipsis_multi(){
	overflow hidden
	text-overflow ellipsis
	display -webkit-box
	-webkit-box-orient vertical
	word-wrap break-word 
}

gradation(){
	background-image -moz-linear-gradient(left, #45b340 0%, #0e8942 19.95%, #3a528c 34.2%, #663fa2 52.34%, #b25855 70.46%, #fe8212 100%);
	background-image -o-linear-gradient(left, #45b340 0%, #0e8942 19.95%, #3a528c 34.2%, #663fa2 52.34%, #b25855 70.46%, #fe8212 100%);
	background-image -webkit-linear-gradient(left, #45b340 0%, #0e8942 19.95%, #3a528c 34.2%, #663fa2 52.34%, #b25855 70.46%, #fe8212 100%);
	background-image linear-gradient(left, #45b340 0%, #0e8942 19.95%, #3a528c 34.2%, #663fa2 52.34%, #b25855 70.46%, #fe8212 100%);
}