html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video
	margin 0
	padding 0
	border 0
	font-size 100%
	font inherit
	vertical-align baseline
	border-radius 0

body
	line-height 1.4

ol, ul
	list-style none

blockquote, q
	quotes none

blockquotebefore, blockquoteafter, qbefore, qafter
	content ''
	content none

table
	border-collapse collapse
	border-spacing 0

th,td
	word-break break-all

caption,legend
	position absolute
	top 0
	left 0
	width 1px
	height 1px
	overflow hidden
input, select
	padding 0
	font inherit
	vertical-align middle
	outline-offset 0
	border 1px solid #ccc
	border-radius 0
	-webkit-border-radius 0
	box-sizing border-box
	&[type=text],&[type=password],&[type=tel]
		appearance none
		-moz-appearance none
		-webkit-appearance none
		background #FFF
select
	border none
	border-radius 0
	-webkit-border-radius 0
	appearance none
	-moz-appearance none
	-webkit-appearance none
	&::-ms-expand {
		display: none;
	}

a
	color inherit
	text-decoration none
	border-radius 0
	&::focus
		box-sizing border-box
img
	width auto
@media screen and (max-width: 960px) {
	img{
		width 100%
	}
}