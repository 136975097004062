@import "font.css"
@require "_inc/mixin.styl"
@require "_inc/reset.styl"
@require "_inc/var.styl"

body{
	font-family 'Noto Sans KR'
	font-style normal
	font-weight 400
}

#wrap{
	min-width 1150px
	color #444
	font-size 16px
}

#skipNavi{
	a{
		display block
		width 100%
		height 0
		font-weight 200
		background #f2f2f2
		text-align center
		line-height @height
		overflow hidden
		&:focus{
			height 50px
			line-height @height
		}
	}
}

.select{
	position relative
	display inline-block
	height 37px
	border _border1_line
	box-sizing border-box
	&:after{
		content ''
		display inline-block
		position absolute
		top 16px
		right 13px
		width 0
		height 0
		border-top 4px solid #444
		border-left 4px solid transparent
		border-right 4px solid transparent
	}
	select{
		width 100%
		height 100%
		padding 0 30px 0 10px
		color #444
		font-size 14px
		background transparent
	}
}

.pagination{
	clearfix()
	margin 30px 0 0
	text-align center
	.paginate_inner{
		display inline-block
		text-align center
		.direction{
			display inline-block
			width 33px
			height 32px
			border _border1_line
			vertical-align top
			box-sizing border-box
			&.disabled{
				opacity 0.4
			}
			img{
				width auto
			}
		}
		.m_prev, .m_next{
			display none
		}
		span{
			display inline-block
			vertical-align top
			padding 0 15px
			a{
				display inline-block
				min-width 33px
				height 32px
				margin 0 2px
				padding 0 5px
				border _border1_line
				color #666
				font-size 12px
				line-height @height
				box-sizing border-box
				&.on{
					color #FFF
					background _bgC2
				}
			}
		}
	}
}

@media screen and (max-width: 960px){
	.pagination{
		.paginate_inner{
			span{
				a{
					margin 0
					display none
					&.on{
						display block
					}
				}
			}
			.first, .last, .next, .prev{
				display none
			}
			.m_prev, .m_next{
				display inline-block
				img{
					width auto
				}
			}
		}
	}
}

.tabs{
	clearfix()
	li{
		position relative
		float left
		border _border1_line
		text-align center
		box-sizing border-box
		cursor pointer
		&.active{
			border none
			color _fontC1 !important
			background #298718
		}
		a{
			display block
			height 100%
			text-align center
		}
	}
}

.intro{
	background url(/resources/images/common/bg_header_intro.jpg) no-repeat 50%
	background-size cover
}
.business{
	background url(/resources/images/common/bg_header_business.jpg) no-repeat 50%
	background-size cover
}
.technique{
	background url(/resources/images/common/bg_header_technique.jpg) no-repeat 50%
	background-size cover
}
.ir_pr{
	background url(/resources/images/common/bg_header_irpr.jpg) no-repeat 50%
	background-size cover
}
.recruitment{
	background url(/resources/images/common/bg_header_recruitment.jpg) no-repeat 50%
	background-size cover
}

#header{
	position relative
	height 370px
	text-align center
	&.main{
		height 632px !important
	}
	nav{
		position absolute
		top 40px
		left 0
		right 0
		width 1150px
		margin 0 auto
		min-height 80px
		background _bgC1
		transition width .4s ease
		-ms-transition width .4s ease
		-webkit-transition width .4s ease
		transition-origin 50%
		-ms-transition-origin 50%
		-webkit-transition-origin 50%
		z-index 2
		&.on{
			position fixed
			width (100% + 1px)
			left calc(50% - 1px)
			right 0
			top 0
			margin 0
			min-height 65px
			z-index 53
			border-bottom _border1_line
			transition width .4s ease
			-ms-transition width .4s ease
			-webkit-transition width .4s ease
			transform-origin center
			-ms-transform-origin center
			-webkit-transform-origin center
			transform translate(-50%, 0)
			animation-name fade_toggle
			animation-duration .4s
			#logo{
				top 11px
				left 85px
			}
			#gnbBox{
				#gnb {
					& > li {
						height 65px
						&>a{
							line-height @height
						}
						& > ul {
							top 65px
							left calc(50% - 1px)
							width 100%
							height 55px
							margin 0
							border-bottom 1px solid #efefef
							transition width .4s ease
							-ms-transition width .4s ease
							-webkit-transition width .4s ease
							transform-origin center
							-ms-transform-origin center
							-webkit-transform-origin center
							transform translate(-50%, 0)
							animation-name fade_toggle
							animation-duration .5s
							li{
								line-height 55px
							}
						}
					}
				}
				.btn_site{
					right 0px
					height 65px
					line-height @height
				}
				.lng{
					top 0
					right 130px
					height 65px
					a{
						color #404040
						font-size 13px
						font-weight 300
						line-height 65px
						opacity 0.7
						&.on{
							font-weight 500
							opacity 1
						}
						&:first-child:before{
							display none
						}
						&:before{
							content ''
							display inline-block
							position absolute
							top  6px
							left 0
							width 1px
							height 9px
							background #797979
						}
					}
				}
			}
		}
	}
}

#logo{
	position absolute
	top 20px
	left 35px
	width 108px
	height 38px
	background url(/resources/images/common/logo_top.png) no-repeat 50%
	z-index 53
	a{
		display block
		height 100%
		texthidden()
	}
}

#btnGnb {
	display none
	position absolute
	top 85px
	right 100px
	width 30px
	height @width
	background url(/resources/images/common/btn_menu.png) no-repeat 50%
	background-size cover
	texthidden()
	z-index 51
}

#gnbBox{
	position absolute
	top 0
	left 0
	right 0
	bottom 0
	z-index 52
	#gnb{
		width 550px
		margin 0 auto
		clearfix()
		&>li{
			float left
			width 110px
			height 80px
			text-align center
			&:hover{
				&>a{
					color _fontC2
					&+ul{
						display block
						z-index 54
						&>li{
							a{
								&:hover{
									color _fontC2
								}
							}
						}
					}
				}
			}
			&>a{
				position relative
				font-size 20px
				font-weight 500
				line-height @height
				&.on{
					color _fontC3
					&+ul{
						display block
					}
				}
			}
			&>ul{
				display none
				position absolute
				left 0
				right 0
				top 80px
				width 1150px
				height 60px
				margin 0 auto
				text-align center
				background #e9eaea
				z-index 52
				&>li{
					position relative
					display inline-block
					padding 0 30px
					color #444
					font-size 14px
					font-weight 500
					line-height 60px
					&:nth-child(1){
						&:after{
							display none
						}
					}
				}
			}
		}
	}
	.btn_site{
		position absolute
		top 0
		right 0
		width 130px
		height 80px
		color _fontC1
		font-size 16px
		line-height @height
		background _color04
		overflow hidden
		a{
			display block
		}
	}
	.lng{
		position absolute
		top -29px
		right 0
		width 130px
		height 80px
		text-align center
		box-sizing border-box
		a{
			position relative
			margin-left 10px
			padding-left 10px
			color _fontC1
			font-size 13px
			font-weight 300
			line-height 0
			box-sizing border-box
			opacity 0.6
			&.on{
				font-weight 500
				opacity 1
			}
			&:first-child:before{
				display none
			}
			&:before{
				content ''
				display inline-block
				position absolute
				top  6px
				left 0
				width 1px
				height 9px
				background _bgC1
				opacity 0.6
			}
		}
	}
	#gnbClose{
		@extend #btnGnb
		background url(/resources/images/common/btn_close.png) no-repeat 50%
		background-size cover
	}
}
.dim{
	display none
	position fixed
	top 0
	left 0
	right 0
	width 100%
	height 100%
	background rgba(0,0,0,0.7)
	z-index 53
}
#siteMapBox{
	display block
	position fixed
	top 0
	right 0
	width 0
	height 100%
	background _bgC1
	z-index 54
}

#siteMap{
	position relative
	padding 185px 25px 0 25px
	box-sizing border-box
	&>li{
		padding 30px 0 30px 20px
		border-top _border1_line
		text-align left
		&:first-child{
			border-top none
		}
		&>a{
			color _fontC2
			font-size 26px
			font-weight 500
		}
		ul{
			margin-top 15px
			li{
				display inline-block
				padding-left 35px
				color #444
				font-size 18px
				box-sizing border-box
				&:first-child{
					padding-left 0
				}
				&:hover{
					color _fontC3
				}
			}
		}
	}
}

#siteClose{
	display none
	position absolute
	top 57px
	right 59px
	width 34px
	height 34px
	background url(/resources/images/common/btn_close.png) no-repeat
	texthidden()
}

.page_tit{
	display inline-block
	margin-top 154px
	color _fontC1
	font-size 28px
	text-align center
	background-size auto 56px
	z-index 51
}
.page_txt{
	display block
	margin-top 15px
	color _fontC1
	font-size 16px
	font-weight 200
	text-align center
	z-index 51
}

.bx-wrapper{
	border none !important
	z-index 1 !important
}
.bx-wrapper, .bx-viewport, .bxslider, .bxslider>div{
	height 100% !important
}

.bxslider div{
	width 100%
	height 100%
	background-size cover !important
	&:first-child{
		background url(/resources/images/main/img_main_slide01.jpg) no-repeat 50% 50%
	}
	&:nth-child(2){
		background url(/resources/images/main/img_main_slide02.jpg) no-repeat 50% 50%
	}
	&:nth-child(3){
		background url(/resources/images/main/img_main_slide03.jpg) no-repeat 50% 50%
	}
	&:nth-child(4){
		background url(/resources/images/main/img_main_slide04.jpg) no-repeat 50% 50%
	}
}

.bx-wrapper .bx-pager{
	top 470px
	left 50%
	margin-left -90px
	z-index 800
	&.bx-default-pager a{
		width 35px !important
		height 4px !important
		border-radius 0 !important
		background #4b4b4b !important
		&:hover, &:focus, &.active{
			background #ed1b23 !important
		}
	}
}

#contents{
	position relative
	width 1150px
	margin 0 auto
	padding 90px 0 120px 0
	.c_tit{
		margin-bottom 30px
		color #222
		font-size 32px
		font-weight 700
		text-align center
	}
	.c_sub_tit{
		color _color05
		font-size 26px
		font-weight 200
		text-align center
	}
	.c_sub_tit02{
		margin-top 30px
		text-align center
		color #444
		font-size 16px
	}
}

#location{
	position absolute
	top -41px
	left 0
	right 0
	width 100%
	height 74px
	&>nav{
		max-width 1150px
		height 100%
		margin 0 auto
		background _bgC2
		box-shadow 0 2px 40px -8px #000
		-moz-box-shadow 0 2px 40px -8px #000
		-webkit-box-shadow 0 2px 40px -8px #000
		box-sizing border-box
		clearfix()
		span,a{
			display inline-block
			height 100%
			color _fontC1
			font-size 16px
			font-weight 200
			line-height 74px
			box-sizing border-box
			&.depth1{
				padding-left 35px
				
			}
			&.depth2{
				padding-left 40px
			
			}
			&.home{
				width 16px
				margin-right 35px
				background url(/resources/images/company/ico_location_home.png) 50% no-repeat
				texthidden()
			}
		}
		span{
			float left
			position relative
			&:first-child:before{
				display none
			}
			&:before{
				content ''
				position absolute
				top 34px
				left 17px
				width 6px
				height 6px
				border-top 1px solid #FFF
				border-right 1px solid #FFF
				transform rotate(45deg)
				opacity 0.5
			}
		}
		a{
			float right
		}
	}
}

#footer{
	height 285px
	background #222
	border-top _border3_line
	.f_gradation{
		display block
		width 100%
		height 5px
		gradation()
	}
	.footer_inner{
		position relative
		max-width 1150px
		margin 0 auto
		padding-top 35px
		box-sizing border-box
		clearfix()
		.footer_logo{
			position absolute
			left 0
			width 144px
			height 70px
			background url(/resources/images/common/logo_footer.png) no-repeat
		}
		.center{
			float left
			margin-left 235px
			.key_num{
				p{
					margin-bottom 8px
					color #FFF
					font-size 14px
				}
				span{
					color _fontC3
					font-size 16px
				}
			}
			&>p, address{
				color #999
				font-size 14px
				font-weight 200
				line-height 1.45
			}
			address{
				margin 20px 0 10px
			}
		}
		.family{
			float right
			width 240px
			&.select{
				position relative
				display inline-block
				height 41px
				border 1px solid #999
				&:after{
					content ''
					display inline-block
					position absolute
					top 19px
					right 13px
					width 0
					height 0
					border-top 5px solid #999
					border-left 5px solid transparent
					border-right 5px solid transparent
				}
				select{
					width 100%
					height 100%
					padding 0 30px 0 10px
					color #999
					font-size 14px
					font-weight 200
					background transparent
				}
			}
		}
	}
}


@media screen and (max-width: 960px){

	#wrap{
		min-width 100%
	}

	#contents{
		width calc(100% - 20px)
		padding-left 10px
		padding-right 10px
	}
}

@media screen and (max-width: 560px){

	#contents{
		padding 70px 10px 50px
		.c_tit{
			margin-bottom 15px
			font-size 17px
		}
		.c_sub_tit{
			font-size 13px
		}
	}
}